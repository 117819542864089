import React from "react";
import { useEffect } from "react";
import { addScaleCorrection, AnimatePresence, motion } from "framer-motion/dist/es/index";

/* import all the component of the home page */

import Intro from "../Intro/Intro";
import MiniGallery from "../MiniGallery/MiniGallery";
import BooleanGallery from "../BooleanGallery/BooleanGallery";
import SkillSection from "../SkillSection/SkillSection";
import Dragger from "../Dragger/Dragger";
import Footer from "../Footer/Footer";

import Case2 from "../Case/Case";
import { DiGroovy } from "react-icons/di";

import End from "../End/End";
import MemoLogoCon from "../LogoCon";

function Home() {
    /*This reset the body height cause the script in singla case component act on that with elementDragControls.style */

    useEffect(() => {
        document.body.style.height = "100%";
    });

    window.scrollTo(0, 0);

    return (
        <motion.div variants={variants} exit="exit">
            <div className="mainWrapper">
                <Intro />
                <div className={"spacer"}></div>
                <MiniGallery />
                <div className={"spacer"}></div>
                <BooleanGallery />
                <div className={"spacer"}></div>
                <SkillSection />
                <div className={"spacer"}></div>
                <Dragger />
                <div className={"spacer"}></div>
            </div>

            <End />
            <Footer />
        </motion.div>
    );
}

const variants = {
    init: {
        x: "100vw",
        opacity: 0,
    },

    anim: {
        x: 0,
        opacity: 1,
        transition: {
            type: "spring",
            duration: 1000,
            ease: "easeInOut",
            damping: 20,
        },
    },

    exit: {
        x: "-100vw",
        opacity: 0,
        transition: {
            type: "spring",
            duration: 6000,
            ease: "easeInOut",
            damping: 20,
        },
    },
};

export default Home;
