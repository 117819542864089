import logo from "./logo.svg";
import "./App.css";

import { AnimatePresence } from "framer-motion/dist/es/index";

import { Switch, Route, useLocation } from "react-router-dom";

//Main Components
import Case from "./components/Case/Case";
import Home from "./components/Home/home";
import Gallery from "./components/Gallery/Gallery";

/* videos */
import VideoElla from "./images/ella/ella.mp4";
import CaseExternal from "./components/Case/CaseExternal";

function App() {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.key}>
        {/* HOME--------------------------------------------------------------------------- */}
        <Route path="/" exact component={Home} />

        {/* GALLERY--------------------------------------------------------------------------- */}
        <Route path="/gallery">
          <div className="mainWrapperGallery">
            <Gallery />
          </div>
        </Route>

        {/* CASES START--------------------------------------------------------------------------- */}

        <Route path="/case1">
          <Case
            miniTitle={"+CASE+01"}
            firstTitle={"+Niagara"}
            secondTitle={"+has+balls"}
            description="   Logo for “Niagara has balls”, a club of Avid Ball Python
            hobbyists. These snakes are called “ball” because of their
            defensive posture of coiling into a tight ball with their
            heads."
            imageIndex={"1"}
            infos={infos1}
          />
        </Route>

        <Route path="/case2">
          <Case
            miniTitle={"+CASE+02"}
            firstTitle={"+Beaches"}
            secondTitle={"+OBGYN"}
            description=" Restyling of an OBGYN practice's website based in
            Jacksonville(FL). The new design is more accessible, clean,
            and easy to navigate. I also developed a set of custom stationery designs for a cohesive branding experience.
              "
            imageIndex={"2"}
            infos={infos2}
            videoColor={"rgb(145, 184, 165)"}
          />
        </Route>

        <Route path="/case3">
          <CaseExternal
            miniTitle={"+CASE+03"}
            firstTitle={"+Digital"}
            secondTitle={"+BrandGuide"}
            description="Logo, business card, and digital Brandguide for Jason, a psychiatrist that specializes in working with individuals with developmental disabilities, including intellectual disability and autism. 

              "
            imageIndex={"3"}
            infos={infos3}
          />
        </Route>

        <Route path="/case4">
          <Case
            miniTitle={"+CASE+04"}
            firstTitle={"+Laura"}
            secondTitle={"+Petiford"}
            description="
            Logo and personal website for a licensed Marriage and Family
            therapist with an eye to the holistic approach. Laura is
            also a great writer with many publications.
          "
            imageIndex={"4"}
            infos={infos4}
            videoColor={"rgb(221, 197, 122)"}
          />
        </Route>

        <Route path="/case5">
          <Case
            miniTitle={"+CASE+05"}
            firstTitle={"+Air"}
            secondTitle={"+Script"}
            description="
            Airscript is the easy way for patients to get their scripts filled and delivered. It will allow patients to order, pay and request delivery of their medication all from within the portal.
          "
            imageIndex={"5"}
            infos={infos5}
          />
        </Route>

        <Route path="/case6">
          <Case
            miniTitle={"+CASE+06"}
            firstTitle={"+Ella"}
            secondTitle={"+health"}
            description="
            Ella is a telemedicine provider of women's health providing telemedicine consultations for things like birth control/contraception, sexual health and hormone therapy.
          "
            imageIndex={"6"}
            infos={infos6}
            videoColor={"rgb(191, 212, 220)"}
          />
        </Route>

        <Route path="/case7">
          <Case
            miniTitle={"+CASE+07"}
            firstTitle={"+Petya"}
            secondTitle={"+Praxis"}
            description=" Petya is a Psychotherapist, specializing in behavior therapy and Sex Therapy, treating a wide range of mental disorders and problems with modern and scientifically recognized methods. "
            imageIndex={"7"}
            infos={infos7}
            /* videoColor={"rgb(191, 212, 220)"} */
          />
        </Route>

        <Route path="/case8">
          <Case
            miniTitle={"+CASE+08"}
            firstTitle={"+Andrew"}
            secondTitle={"+Signature"}
            description=" Andrew is a therapist in San Francisco, California. He provide talk therapy, similar to a psychologist, to help  clients with anxiety, depression, relationship problems, etc. Many of his clients work in tech (e.g. software engineers)."
            imageIndex={"8"}
            infos={infos8}
            /* videoColor={"rgb(191, 212, 220)"} */
          />
        </Route>

        <Route path="/case9">
          <Case
            miniTitle={"+CASE+09"}
            firstTitle={"+Rodent"}
            secondTitle={"+Mart"}
            description="Brand identity for a specialized breeder service located in Niagara Falls Ontario, Canada. All are ethically raised animals.
              "
            imageIndex={"3"}
            infos={infos9}
          />
        </Route>

        {/* CASES END--------------------------------------------------------------------------- */}
      </Switch>
    </AnimatePresence>
  );
}

/* component link for info */
const Infolink = (props) => {
  return (
    <a href={props.linkSite} target="_blank">
      {props.nameSite}
    </a>
  );
};

/* Arrays for cases */

const infos1 = [
  ["services", "Logodesign"],
  ["tools", "Illustrator, Affinity Designer, Photoshop"],
  ["timeframe", "1 month"],
  ["location", "Niagara"],
];

const infos2 = [
  ["services", "Sitebuilding, Stationery"],
  ["tools", "Wordpress, Elementor, Adobe Suite"],
  ["timeframe", "1 month"],
  [
    "website",
    <Infolink
      linkSite="https://www.toplinemd.com/beaches-obgyn/"
      nameSite="beaches-obgyn.com"
    />,
  ],
];

const infos3 = [
  ["services", "Brand Identity, Developing, Web Design"],
  ["tools", "Adobe Suite, React, Framer-motion"],
  ["timeframe", "1 month"],
  [
    "website",
    <Infolink
      linkSite="https://test-brandguide.netlify.app/"
      nameSite="DVPMbrandGuide.com"
    />,
  ],
];

const infos4 = [
  ["services", "logodesign, sitebuilding"],
  ["tools", "wordpress"],
  ["timeframe", "1 month"],
  [
    "website",
    <Infolink
      linkSite="https://laurapetiford.com/"
      nameSite="laurapetiford.com"
    />,
  ],
];

const infos5 = [
  ["services", "Logodesign"],
  ["tools", "Illustrator, Affinity Designer"],
  ["timeframe", "1 week"],
  ["location", "Victoria"],
];

const infos6 = [
  ["services", "Logodesign", "sitebuilding"],
  ["tools", "Wordpress, Elementor, Adobe Suite"],
  ["timeframe", "1 month"],
  ["location", "Jacksonville"],
];

const infos7 = [
  ["services", "Logodesign, Stationery"],
  ["tools", "Adobe Suite"],
  ["timeframe", "2 weeks"],
  [
    "website",
    <Infolink
      linkSite="https://www.schuhmann-psychotherapie.de/"
      nameSite="schuhmann-psychotherapie.de"
    />,
  ],
];

const infos8 = [
  ["services", "Html signature"],
  ["tools", "VS code, Figma"],
  ["timeframe", "2 weeks"],
  [
    "website",
    <Infolink
      linkSite="https://andrewkushnick.com"
      nameSite="andrewkushnick.com"
    />,
  ],
];

const infos9 = [
  ["services", "Brand Identity"],
  ["tools", "Adobe Suite"],
  ["timeframe", "1 month"],
  ["location", "Ontario"],
];

export default App;
