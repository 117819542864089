/* Case 1 */
import Nhb1 from "../images/nhb/1.svg";
import Nhb2 from "../images/nhb/2.jpg";
import Nhb3 from "../images/nhb/3.svg";
import Nhb4 from "../images/nhb/4.jpg";
import Nhb5 from "../images/nhb/5.jpg";

/* Case 2 */
import Obj1 from "../images/obj/1.png";
import Obj2 from "../images/obj/2.jpg";
import Obj3 from "../images/obj/3.jpg";
import Obj4 from "../images/obj/4.png";
import Obj5 from "../images/obj/5.jpg";
import Obj6 from "../images/obj/6.jpg";
import ObjVideo1 from "../images/obj/bobj.mp4";

/* Case 3 */
import Rm1 from "../images/test/1.svg";
import Rm2 from "../images/test/2.jpg";
import Rm3 from "../images/test/3.svg";
import Rm4 from "../images/test/4.jpg";
import Rm5 from "../images/test/5.jpg";

/* Case 4 */
import Lp1 from "../images/lp/lp.jpg";
import LpVideo1 from "../images/lp/lp.mp4";

/* Case 5 */
import As1 from "../images/as/1.svg";
import As2 from "../images/as/2.jpg";
import As3 from "../images/as/3.svg";
import As4 from "../images/as/4.jpg";
import As5 from "../images/as/5.jpg";

/* Case 6 */
import Ella1 from "../images/ella/1.png";
import Ella2 from "../images/ella/ella.mp4";

/* case 7 */
import Ps from "../images/ps/1.svg";
import Ps2 from "../images/ps/2.png";
import Ps3 from "../images/ps/3.png";
import Ps4 from "../images/ps/4.png";
import Ps5 from "../images/ps/5.png";

/* case 8 */
import Ak1 from "../images/ak/1.jpg";
import Ak2 from "../images/ak/2.jpg";

/* arrays */
let case1 = [Nhb1, Nhb2, Nhb3, Nhb4, Nhb5];
let case2 = [Obj1, ObjVideo1, Obj2, Obj3, Obj4, Obj5, Obj6];
let case3 = [Rm1, Rm2, Rm3, Rm4, Rm5];
let case4 = [Lp1, LpVideo1];
let case5 = [As1, As2, As3, As4, As5];
let case6 = [Ella1, Ella2];
let case7 = [Ps, Ps2, Ps3, Ps4, Ps5];
let case8 = [Ak1, Ak2];

let images = [0, case1, case2, case3, case4, case5, case6, case7, case8];

export default images;
