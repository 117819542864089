import { React, useState, useRef, useEffect, useLayoutEffect } from "react";

import { BsArrowDownLeftCircleFill } from "react-icons/bs";
import Footer from "../Footer/Footer";
import TextIcon from "../TextIcon/TextIcon";
import ReactPlayer from "react-player";

import { isMobile, isMobileOnly } from "react-device-detect";

import End from "../End/End";

import {
  motion,
  useDragControls,
  useTransform,
  useViewportScroll,
  useMotionValue,
  useElementScroll,
} from "framer-motion/dist/es/index";

import { useInViewScroll } from "framer-motion-hooks";

import images from "../Images";

import { BsArrowRightShort } from "react-icons/bs";
import { BsArrowLeftShort } from "react-icons/bs";
import { Link } from "react-router-dom";

/* Smooth Image */
import RenderSmoothImage from "render-smooth-image-react";
import "render-smooth-image-react/build/style.css";
import PaperCase from "../PaperCase/PaperCase";

import TextBlock from "../TextBlock/TextBlock";

import styled from "styled-components";
import { BsArrowUpRightCircleFill } from "react-icons/bs";

import LaptopImage from "../../images/macbook.png";

/* styled-components------------------------------------------- */

const Ext = styled.div`
  width: 100%;
  height: fit-content;

  padding-top: 5em;
  padding-bottom: 5em;

  background-color: rgb(28, 28, 28);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 60%;

    @media (max-width: 700px) {
      width: 90%;
    }
  }

  .load {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 3em;
  }

  h1 {
    color: white;
    font-size: clamp(3em, 3vw, 6em);
    padding-bottom: 10px;
    text-align: center;
  }
`;

/* styled-components------------------------------------------- */

function CaseExternal(props) {
  const sectionRef = useRef();

  /* Bigger image on scroll */
  let iconContainer = useRef();
  const progress = useInViewScroll(iconContainer);
  const special = useTransform(progress, [0, 0.1], [70 + "%", 100 + "%"]);

  window.scrollTo(0, 0);

  /* Bigger image on scroll */
  const progressBig = useInViewScroll(iconContainer);
  const scaleAnimationDesktop = useTransform(progressBig, [0, 0.1], [0.7, 1]);
  const scaleAnimationMobile = useTransform(progressBig, [0, 0.1], [1, 1]);

  return (
    <motion.div
      className={"case2"}
      variants={variants}
      initial="init"
      exit="exit"
      animate="anim"
      /*  ref={iconContainer} */
    >
      <section ref={sectionRef}>
        <div className="mainWrapperCase">
          <div className="globalFlex">
            <div className="containerDescription">
              <div className={"description"}>
                <div className={"caseTitle"}>
                  <TextBlock
                    centerAligned={false}
                    miniTitle={props.miniTitle}
                    firstTitle={props.firstTitle}
                    secondTitle={props.secondTitle}
                    miniColor={"#ED0515"}
                    firstColor={"white"}
                    secondColor={"#ED0515"}
                  />
                </div>
              </div>

              <div className={"caseDescription"}>
                <div className="wrap">
                  <motion.p variants={variantsParagraph}>
                    {props.description}
                  </motion.p>
                </div>
              </div>
            </div>

            <motion.div
              className="serviceContainer"
              animate={{ opacity: 1 }}
              initial={{ opacity: 1 }}
            >
              <motion.div
                className="service"
                variants={parentService}
                animate="anim"
                initial="init"
              >
                {props.infos.map((name, index) => {
                  return (
                    <motion.div
                      key={index}
                      variants={variantsService}
                      className="single"
                    >
                      <h4 className="title">{name[0]}</h4>
                      <h4>{name[1]}</h4>
                    </motion.div>
                  );
                })}
              </motion.div>
            </motion.div>
          </div>
        </div>

        <Ext>
          <img src={LaptopImage}></img>

          <div className="load">
            <h1> See the project </h1>

            <motion.a
              href="https://test-brandguide.netlify.app/"
              whileHover={{ scale: 1.2 }}
            >
              <BsArrowUpRightCircleFill
                style={{ fontSize: `clamp(3em,3vw,15em)`, color: `#ED0515` }}
              ></BsArrowUpRightCircleFill>
            </motion.a>
          </div>
        </Ext>

        <div className="mainWrapperCase"></div>

        <Footer />
      </section>
    </motion.div>
  );
}

const variants = {
  init: {
    x: "100vw",
    opacity: 0,
  },

  anim: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 1000,
      ease: "easeInOut",
      damping: 20,
    },
  },

  exit: {
    x: "-100vw",
    opacity: 0,
    transition: {
      type: "spring",
      duration: 6000,
      ease: "easeInOut",
      damping: 20,
    },
  },
};

const variantsParagraph = {
  init: {
    y: "100%",
    opacity: 0,
  },

  anim: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 0.7,
      ease: "easeInOut",
      damping: 20,
      delay: 1.5,
    },
  },
};

const parentService = {
  anim: {
    transition: {
      staggerChildren: 3000,
      staggerDirection: 0,
    },
  },
};

const variantsService = {
  init: {
    x: "100%",
    opacity: 0,
  },

  anim: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 0.7,
      ease: "easeInOut",
      damping: 20,
      delay: 1.5,
    },
  },
};

export default CaseExternal;
