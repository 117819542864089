import * as React from "react";
import { useRef } from "react";

import { motion } from "framer-motion";

import { useInView } from "react-intersection-observer";

const main = {
  hidden: { rotate: 0 },
  visible: {
    rotate: 0,
    transition: {
      duration: 0,
      repeat: Infinity,
      staggerChildren: 0.02,
      staggerDirection: 1,
    },
  },
};

const showLogo = {
  hidden: { scale: 0, rotate: 50 },
  visible: {
    rotate: 0,
    scale: 1,
    transition: {
      scale: { duration: 0.7 },
      rotate: { rotate: 0.7 },
    },
  },
};

const showTm = {
  hidden: { scale: 0 },
  visible: {
    scale: 1,
    transition: {
      scale: { duration: 0.4 },
      repeat: Infinity,
    },
  },
};

const showText = {
  hidden: { x: 100, opacity: 0 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      x: { duration: 0.3 },
      opacity: { duration: 0.6 },
      repeat: Infinity,
    },
  },
};

const showTagline = {
  hidden: { x: 100, opacity: 0 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      x: { duration: 0.3 },
      opacity: { duration: 0.6 },
      repeat: Infinity,
    },
  },
};

function Pictogram(props) {
  let options = { triggerOnce: false, treshold: 0.3 };
  const [ref, inView] = useInView(options);
  return (
    <motion.svg
      ref={ref}
      id="0"
      x={0}
      y={0}
      viewBox="0 0 153.13 155.71"
      xmlSpace="preserve"
      {...props}
      initial="hidden"
      animate={inView ? "visible" : "noAnimation"}
      variants={main}
      width="100%"
      height="auto"
    >
      <style>{".prefix__st6{fill:#303031}"}</style>
      <motion.path
        variants={showLogo}
        d="M94.85 155.71l-18.29-31.68-18.29 31.68-14.12-8.15 19.95-34.57c2.6-4.5 7.25-7.18 12.45-7.18 5.2 0 9.85 2.68 12.45 7.18l19.95 34.57-14.1 8.15z"
        fill="#ffe310"
      />
      <motion.path
        variants={showLogo}
        d="M32.4 140.76l-14.12-8.15 18.28-31.66H0V84.64h39.91c5.2 0 9.85 2.69 12.45 7.19 2.6 4.5 2.6 9.88 0 14.38L32.4 140.76z"
        fill="#6fc2b3"
      />
      <motion.path
        variants={showLogo}
        d="M0 71.07V54.76h36.57L18.29 23.09l14.12-8.15L52.36 49.5c2.6 4.5 2.6 9.88 0 14.38s-7.25 7.19-12.45 7.19H0z"
        fill="#c379b1"
      />
      <motion.path
        variants={showLogo}
        d="M76.56 49.91c-5.2 0-9.85-2.69-12.45-7.19L44.16 8.15 58.28 0l18.29 31.68L94.85 0l14.12 8.15-19.96 34.57c-2.6 4.5-7.25 7.19-12.45 7.19"
        fill="#6d96ce"
      />
      <motion.path
        variants={showLogo}
        d="M153.13 71.07h-39.91c-5.2 0-9.85-2.69-12.45-7.19s-2.6-9.88 0-14.38l19.95-34.55 14.12 8.15-18.28 31.66h36.57v16.31z"
        fill="#ea506e"
      />
      <motion.path
        variants={showLogo}
        d="M120.72 140.76l-19.95-34.55c-2.6-4.5-2.6-9.88 0-14.38s7.25-7.19 12.45-7.19h39.91v16.31h-36.57l18.28 31.66-14.12 8.15z"
        fill="#f7aa64"
      />
      <motion.path
        variants={showLogo}
        /* className="prefix__st6" */
        fill={"rgb(255,255,255)"}
        d="M142.44 0v1.39h-2.74v7.64h-1.58V1.39h-2.73V0zM145.83 0l2.57 4.45L150.98 0h2.15v9.03h-1.59V2.12l-2.74 4.92h-.79l-2.75-4.92v6.91h-1.58V0z"
      />
    </motion.svg>
  );
}

const MemoLogoCon = React.memo(Pictogram);
export default MemoLogoCon;
