import { React, useState, useRef, useEffect, useLayoutEffect } from "react";

/* react icons */
import { DiCss3Full } from "react-icons/di";
import { ImHtmlFive2 } from "react-icons/im";
import * as fa from "react-icons/fa";

import {
  motion,
  useDragControls,
  useTransform,
  useViewportScroll,
  useMotionValue,
  useElementScroll,
} from "framer-motion/dist/framer-motion";

import TextSkill from "../TextSkill/TextSkill";

const Skills = (props) => {
  let iconContainer = useRef();

  // Get the space(%) occupied by the element in the viewport (start and end percentage);
  let coordinate = useRefScrollProgress(iconContainer);

  // ref to sliderContainer, I need to use it as a constraints for slider
  const skillsContainerRef = useRef(0);

  const { scrollYProgress } = useViewportScroll();

  const specialAnimation = useTransform(
    scrollYProgress,
    [coordinate.start, 1],
    [0, -900]
  );

  return (
    <div className={"sk"} ref={iconContainer}>
      <motion.div className={"skillsContainer"} ref={skillsContainerRef}>
        <motion.div style={{ x: specialAnimation }}>
          <motion.div
            className={"dragger"}
            drag="x"
            /* style={{ x: specialAnimation }} */
            dragConstraints={skillsContainerRef}
          >
            {props.name.map((name, index) => {
              return (
                <div className={"singleSkill"}>
                  <div className={"icon"}>{name[2]}</div>

                  <div>
                    <h1>{name[0]}</h1>
                    <h2>{name[1]}</h2>
                  </div>
                </div>
              );
            })}
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

/*
  Takes an optional component ref (or returns a new one)
  and returns the ref, the scroll `start` and `end` percentages
  that are relative to the total document progress.
*/

function useRefScrollProgress(inputRef) {
  const ref = inputRef;
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    const rect = ref.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offsetTop = rect.top + scrollTop;
    setStart(offsetTop / document.body.clientHeight);
    setEnd((offsetTop + rect.height) / document.body.clientHeight);
  });

  return { ref, start, end };
}

export default Skills;
