import { React, useState, useRef, useEffect } from "react";
import {
  motion,
  useDragControls,
  useMotionValue,
} from "framer-motion/dist/framer-motion";
import ReviewSlide from "../ReviewSlide/ReviewSlide";
import MemoStar from "../Icons/star.js";
/* import Icon from "../Icon/Icon"; */

import MemoIconArrow from "../Icons/iconArrow";
import { useInView } from "react-intersection-observer";

import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";

import TextBlock from "../TextBlock/TextBlock";

import { Icon } from "../Icon/Icon";

// component
const Dragger = () => {
  /* state for moving the dragger */
  const [moveLeft, setMoveLeft] = useState(0);

  // ref to sliderContainer, I need to use it as a constraints for slider
  const sliderContainerRef = useRef();
  const slideSize = useRef();

  const references = useRef([]);

  /* IntersectionObserver */

  /* Devo ripassare la destrutturazione di un array */

  const options = { threshold: 0.1 };
  const [refStart, inViewStart, entryStart] = useInView(options);
  const [refEnd, inViewEnd, entryEnd] = useInView(options);

  // ------// detecting changes to window resize
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // ------// detecting changes to window resize

  useEffect(() => {
    // detecting changes on window resize

    // taking the parent width
    let sizeX = sliderContainerRef.current.clientWidth;
    let widthValue = 0;

    if (sizeX >= 1200) {
      widthValue = sizeX / 4;
    } else if (sizeX <= 1200 && sizeX > 800) {
      widthValue = sizeX / 3;
    } else if (sizeX <= 800 && sizeX > 500) {
      widthValue = sizeX / 2;
    } else if (sizeX <= 500 && sizeX > 0) {
      widthValue = sizeX;
    }

    /* this reset the drag position */
    draggableDiv.current.style.transform = "translate3d(0px,0px,0px)";

    //  setting the size of the child in according
    references.current.map((item) => {
      item.style.width = `${widthValue}px`;
    });
  });

  /*  moving slide */
  /*  useEffect(() => {
    console.log(draggableDiv.current.offsetX);
  }); */

  /*  drag controls START ------------------------------------------- */
  const draggableDiv = useRef();
  const cursorContainer = useRef();

  /*  drag controls END ------------------------------------------- */

  return (
    <div className={"dragger"}>
      <div className={"dragger__header"}>
        <div className={"text"}>
          <TextBlock
            centerAligned={false}
            miniTitle={"+Reviews"}
            firstTitle={"+What+people+"}
            secondTitle={"+are+saying."}
            miniColor={"#ED0515"}
            firstColor={"white"}
            secondColor={"#ED0515"}
          />
        </div>

        <div className={"stars"}>
          <Icon model={3} small={true} parallax={1} />
          <Icon model={3} medium={true} parallax={3} />
          <Icon model={3} small={true} parallax={2} filter={true} />
        </div>
      </div>

      <motion.div className={"sliderContainer"} ref={sliderContainerRef}>
        <motion.div
          drag="x"
          dragMomentum={true}
          className={"sliderContainer__sub"}
          /* animate={{ x: moveLeft }} */
          dragConstraints={sliderContainerRef}
          ref={draggableDiv}
        >
          {reviews.map((item, index) => (
            <div
              className={"sliderContainer__slider"}
              key={index}
              ref={(element) => {
                references.current[index] = element;
              }}
            >
              <ReviewSlide text={item}></ReviewSlide>
            </div>
          ))}
        </motion.div>
      </motion.div>

      <div
        className={"arrows"}
        ref={cursorContainer} /* onPointerDown={startDrag} */
      ></div>
    </div>
  );
};

const reviews = [
  "Great work. Very responsive.",

  "Excellent work. It was well designed and was exactly what we're looking for.",

  "Great work, appreciate the design and effort. This is the 4th time you've produced a great logo for us. Awesome work.",

  "This is the third project I've worked on with Hugo, and he's a true professional. His designs are creative and modern. And he's so easy to work with - responsive, friendly, clear and concise.",

  "Simply the best!",

  "Always great working with Hugo. ",

  "Hugo is a great designer. He communicates often and clearly. ",

  "Great logos, very well done as usual.",

  "Awesome work. Very well thought out, thanks for taking the time to do it so well. Could not have thought it would be any better.",

  "Hugo was great to work witth. Excellent communication.",

  "Great design. Easy communication. ",

  "It was great to work with Hugo! He kindly changed all the things we requested and in the end we got a great logo! Thank you! ",

  "Great logo, very responsive to my needs, and did a great job explaining everything to a first-timer",

  "Great job! Very thorough, prompt, and went above and beyond with this project. ",

  "Hugo was very responsive, gave us great designs, and was accurate in following my specific requests.",

  "Hugo created a business card for me that looks really cool! He was incredibly patient and understanding when I requested a number of modifications along the way. He responded quickly and efficiently to these requests. Thanks Hugo for a job well done!",

  "Hugo was very patient with us and always available! He implemented all our ideas and revisions in a very friendly manner! We can highly recommend him and will work with him in the future again!",

  "Hugo was super-responsive and his communication was clear. His turnaround time was really quick. And best of all was the quality of his work. He was creative in experimenting with different designs, and each proposed design was impressive from an artistic standpoint.",

  "Very rapid work and great quality, quick realization of demands. ",

  "If was a pleasure to work with this designer. I highly recommend working with him. ",

  "Hugo created a unique and inspiring design. He put a lot of thoughts into it and his ideas fitted well to the theme. He responded very quickly and was commited to the job. Thank you very much! I really enjoyed going through that process and I am very happy with the result.",

  "_Hugo_ was fantastic to work with. Gave the most creative design from the beginning, was very responsive (!) and helpful, and was able to interpret and apply my ideas and requested changes. I would definitely work with _Hugo_, again! Thank you! :)",

  "Preciso, disponibile e disposto a collaborare. Un ottimo professionista. ",

  "Hugo had been fantastic and very professional. Great design mind for sure. ",
];

export default Dragger;
