import { React, useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  addScaleCorrection,
  AnimatePresence,
  motion,
} from "framer-motion/dist/es/index";
import { useInView } from "react-intersection-observer";
import Work from "../Work/Work";

import Icon from "../Icon/Icon.js";
import { Artboard, Artboard_1 } from "../Artboard/Artboard";
import { BrowserRouter, Switch, Route, Routes, Link } from "react-router-dom";
import Dragger from "../Dragger/Dragger";
import Footer from "../Footer/Footer";

/* previews */
import P_nhb from "../../images/p1_nhb.jpg";
import P_Obj from "../../images/p2_obj.jpg";
import P_rm from "../../images/p3_rm.jpg";
import P_lp from "../../images/p1_lp.jpg";
import P_5 from "../../images/p5.jpg";
import P_6 from "../../images/p6.jpg";
import P_7 from "../../images/p7.jpg";
import P_8 from "../../images/p8.jpg";
import P_9 from "../../images/p9.jpg";

import { BsArrowLeftShort } from "react-icons/bs";

export default function Gallery() {
  window.scrollTo(0, 0);
  return (
    <div className={"fullGallery"}>
      <motion.div
        className={"caseContainer"}
        key="modal"
        variants={variants}
        animate="anim"
        initial="init"
        exit="exit"
      >
        <div className={"workContainer"}>
          <Artboard
            model={"artboard_1"}
            title={"Brand identity for a python hobbyists club"}
            info={"#LOGODESIGN"}
            number={"01"}
            where={"Niagara Falls"}
            image={P_nhb}
            destination={"/case1"}
          />

          <Artboard
            model={"artboard_1"}
            title={"A new website for an OBGYN practice in Florida"}
            info={"#webdesign #sitebuilding #stationery"}
            number={"02"}
            where={"Jacksonville"}
            image={P_Obj}
            destination={"/case2"}
          />

          <Artboard
            model={"artboard_1"}
            title={"Logo and Web-Brandguide for a child psychiatrist"}
            info={"#logodesign #webdesign #Coding #Stationery"}
            number={"03"}
            where={"Maryland"}
            destination={"/case3"}
            image={P_9}
          />
          
        </div>

        <div className={"workContainer"}>
          <Artboard
            model={"artboard_1"}
            title={"Personal Website for an holistic therapist"}
            info={"#webdesign #sitebuilding #logodesign"}
            number={"04"}
            where={"Fairfield"}
            destination={"/case4"}
            image={P_lp}
          />

          <Artboard
            model={"artboard_1"}
            title={"Logo for an online pharmacy"}
            info={"#Brand Identity"}
            number={"05"}
            where={"Victoria"}
            destination={"/case5"}
            image={P_5}
          />

          <Artboard
            model={"artboard_1"}
            title={
              "Website and logo for a women's health telemedicine provider"
            }
            info={"#webdesign #logodesign"}
            number={"06"}
            where={"Jacksonville"}
            destination={"/case6"}
            image={P_6}
          />
        </div>

        <div className={"workContainer"}>
          <Artboard
            model={"artboard_1"}
            title={
              "Logo for a psychotherapist specialized in behavior therapy "
            }
            info={"#Brand Identity #Stationery"}
            number={"07"}
            where={"Bavaria"}
            destination={"/case7"}
            image={P_7}
          />

          <Artboard
            model={"artboard_1"}
            title={"HTML signature for a techie therapist"}
            info={"#Html signature"}
            number={"08"}
            where={"San Francisco"}
            destination={"/case8"}
            image={P_8}
          />

          <Artboard
            model={"artboard_1"}
            title={"Brand identity for a breeder service"}
            info={"#Brand Identity"}
            number={"09"}
            where={"Ontario"}
            destination={"/case9"}
            image={P_rm}
          />
        </div>
      </motion.div>

      <Footer></Footer>
    </div>
  );
}

const variants = {
  init: {
    x: 0,
    opacity: 1,
  },

  anim: {
    x: 0,
    opacity: 1,
  },

  exit: {
    x: "-100vw",
    opacity: 0,
    transition: {
      type: "spring",
      duration: 3000,
      ease: "easeInOut",
      damping: 20,
    },
  },
};
