import { React, useState, useRef, useEffect, useLayoutEffect } from "react";

import styled from "styled-components";
import { css } from "styled-components";

import {
  motion,
  useDragControls,
  useTransform,
  useViewportScroll,
  useMotionValue,
  useElementScroll,
} from "framer-motion/dist/es/index";

import { useMotionAsState, useInViewScroll } from "framer-motion-hooks";

import { isFirefox } from "react-device-detect";

/* 3D icons START------------------------------------------------------------ */

import heart from "../../images/Heart.png";
import heartSmall from "../../images/Heart2.png";

import hand from "../../images/hand.png";
import star from "../../images/star.png";

let coolIcons = [hand, heart, heartSmall, star];

/* 3D icons START------------------------------------------------------------ */

const Icon_SC = styled.div`
  ${({ big }) =>
    big &&
    css`
      img {
        width: clamp(300px, 25vw, 700px);
        height: auto;

        @media (max-width: 800px) {
          width: clamp(200px, 40vw, 700px);
        }
      }
    `}

  ${({ medium }) =>
    medium &&
    css`
      img {
        width: clamp(150px, 13vw, 700px);
        height: auto;

        @media (max-width: 800px) {
          width: clamp(50px, 30vw, 700px);
        }

        @media (max-width: 500px) {
          width: clamp(50px, 35vw, 700px);
        }
      }
    `}


    ${({ small }) =>
    small &&
    css`
      img {
        width: clamp(100px, 2vw, 700px);
        height: auto;

        @media (max-width: 800px) {
          width: clamp(100px, 10vw, 700px);
        }

        @media (max-width: 500px) {
          width: clamp(60px, 10vw, 700px);
        }
      }
    `}
`;

export const Icon = (props) => {
  let iconContainer = useRef();

  const progress = useInViewScroll(iconContainer);

  const hand = useTransform(progress, [0, 1], [-50, 50]);
  const heart = useTransform(progress, [0, 1], [-100, 20]);

  const star_1 = useTransform(progress, [0, 1], [20, 0]);
  const star_2 = useTransform(progress, [0, 1], [20, -20]);
  const star_3 = useTransform(progress, [0, 1], [-20, 20]);

  let animations = [hand, star_1, star_2, star_3, heart];

  return (
    <Icon_SC
      ref={iconContainer}
      small={props.small}
      big={props.big}
      medium={props.medium}
      style={props.filter ? { filter: "blur(4px)" } : null}
    >
      <motion.img
        alt={isFirefox ? "1" : ""}
        style={{ y: animations[props.parallax] }}
        src={coolIcons[props.model]}
        animate={
          props.anima
            ? {
                rotate: [-30, 30],
              }
            : null
        }
        transition={{
          repeat: Infinity,
          duration: 0.5,
          repeatType: "mirror",

          /* repeatDelay: 0.5, */
        }}
      ></motion.img>
    </Icon_SC>
  );
};
