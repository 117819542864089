import { React } from "react";
import { motion } from "framer-motion/dist/es/index";
import { useInView } from "react-intersection-observer";

import styled from "styled-components";

import { css } from "styled-components";

/* styled components START -----------------------------------------------> */

const TextBlock_SC = styled.div`
  .container {
    width: 100%;
    height: 100%;

    margin-bottom: 20px;

    display: flex;
    align-items: center;

    /* center-aligned-version START ---------------------------------------------------- */

    ${({ centerAligned }) =>
      centerAligned &&
      css`
        justify-content: center;
        align-items: center;
      `}

    /* center-aligned-version End ---------------------------------------------------- */

    .text-container {
      width: fit-content;
      height: fit-content;

      display: flex;
      justify-content: start;
      align-items: flex-start;

      /* center-aligned-text START ---------------------------------------------------- */

      ${({ centerAligned }) =>
        centerAligned &&
        css`
          align-items: center;
        `}
      /* center-aligned-text End ---------------------------------------------------- */

      flex-direction: column;

      div {
        height: fit-content;
        background-color: rgba(137, 43, 226, 0);
        overflow: hidden;
      }

      .miniTitle {
        display: flex;
        flex-wrap: nowrap;

        .singleLettersStyle {
          color: ${(props) => props.miniColor};

          font-family: "Just Another Hand", cursive;
          display: inline-block;

          font-size: clamp(3em, 3vw, 3em);

          @media (max-width: 700px) {
            font-size: 8vw;
          }
        }
      }

      .firstTitle {
        display: flex;
        flex-wrap: wrap;

        .singleLettersStyle {
          color: ${(props) => props.firstColor};
          display: inline-block;
          font-weight: bold;

          font-size: 6vw;

          @media (max-width: 800px) {
            font-size: 12.5vw;
          }

          ${({ bigger }) =>
            bigger &&
            css`
              font-size: 7vw;
              

              @media (max-width: 800px) {
                font-size: 14vw;
              }
            `}
        }

        .lettersSpace {
          /* flex-basis: 100%; */
          display: block;
          width: 0.7em;
        }
      }

      .secondTitle {
        display: flex;
        flex-wrap: wrap;

        .singleLettersStyle {
          color: ${(props) => props.secondColor};
          display: inline-block;

          font-weight: bold;

          font-size: 6vw;

          @media (max-width: 800px) {
            font-size: 12.5vw;
          }

          ${({ bigger }) =>
            bigger &&
            css`
              font-size: 7vw;

              @media (max-width: 800px) {
                font-size: 14vw;
              }
            `}
        }
      }
    }
  }

  .wordContainer {
    display: flex;
    flex-wrap: nowrap;
    margin-right: 0.6em;
    overflow: visible;
  }
`;
/* styled components END -----------------------------------------------> */

function TextBlock(props) {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  return (
    <TextBlock_SC
      centerAligned={props.centerAligned}
      bigger={props.bigger}
      miniColor={props.miniColor}
      firstColor={props.firstColor}
      secondColor={props.secondColor}
    >
      <div className={"wrapper"}>
        <div className={"container"}>
          <motion.div
            className={"text-container"}
            variants={grandVariants}
            animate={inView ? "anim" : "null"}
            initial="init"
            ref={ref}
            style={{ alignItems: props.justifyText }}
          >
            <motion.div className={"miniTitle"} variants={parentVariants}>
              {movingLetters(props.miniTitle)}
            </motion.div>
            <motion.div className={"firstTitle"} variants={parentVariants}>
              {movingLetters(props.firstTitle)}
            </motion.div>{" "}
            <motion.div className={"secondTitle"} variants={parentVariants}>
              {movingLetters(props.secondTitle)}
            </motion.div>
          </motion.div>
        </div>
      </div>
    </TextBlock_SC>
  );
}

// animations

const grandVariants = {
  init: {},

  anim: {
    transition: {
      staggerChildren: 0.5,
      staggerDirection: 1,
    },
  },
};

const parentVariants = {
  init: {},

  anim: {
    transition: {
      /*  staggerChildren: 0.05, */
      staggerChildren: 0.04,
      staggerDirection: 1,
    },
  },
};

const childVariants = {
  init: {
    x: 50,
    y: 150,
    rotate: 30,

    opacity: "0%",
  },

  anim: {
    x: 0,
    y: 0,
    rotate: 0,

    opacity: "100%",

    transition: {
      type: "spring",
      duration: 0.2,
      ease: "easeInOut",
      damping: 20,
    },
  },
};

function movingLetters(letters, tint) {
  /* funzione per analizzare la lettera prima di inserirla, l'if statement non funziona nel jsx  */

  const analizeLetter = (singleLetters) => {
    if (singleLetters.includes("_")) {
      return (
        <motion.div
          className={"lettersSpace"}
          variants={childVariants}
          style={{ color: tint }}
        ></motion.div>
      );
    } else {
      return (
        <motion.h1
          className={"singleLettersStyle"}
          variants={childVariants}
          style={{ color: tint }}
        >
          {singleLetters}
        </motion.h1>
      );
    }
  };

  const lettersGroup = Array.from(letters);

  /* Conto il numero di + */
  var count = letters.split("+").length - 1;
  /* console.log(count); */

  /* divido la stringa in singoli arrays di parole */
  let singleWordsGroup = letters.split("+");

  /* Devo rimuovere il primo elemento da singleWords. Si tratta di un refuso che al momento non riesco ad eliminare */
  singleWordsGroup.shift();
  /* console.log(Array.from(singleWordsGroup[1])); */

  /* devo fare un array di arrays con le lettere separate; */
  /*  il primo indice consente di muoversi tra le varie parole, il secondo indice è
  un altro array con le lettere separate */

  /* console.log(singleWordsGroup); */

  let definitive = [];

  Object.values(singleWordsGroup).map(function (key, index) {
    definitive.push(Array.from(key));
  });

  /*   console.log(definitive); */

  const a = Object.values(definitive);

  /* console.log(a); */

  function mapping(number) {
    return (
      <>
        <div className={"wordContainer"}>
          {a[number].map((item, index) => {
            return <>{analizeLetter(item)}</>;
          })}
        </div>
      </>
    );
  }

  if (count == 1) {
    return mapping(0);
  } else if (count == 2) {
    return (
      <>
        {mapping(0)}
        {mapping(1)}
      </>
    );
  } else if (count == 3) {
    return (
      <>
        {mapping(0)}
        {mapping(1)}
        {mapping(2)}
      </>
    );
  } else if (count == 4) {
    return (
      <>
        {mapping(0)}
        {mapping(1)}
        {mapping(2)}
        {mapping(3)}
      </>
    );
  } else if (count == 5) {
    return (
      <>
        {mapping(0)}
        {mapping(1)}
        {mapping(2)}
        {mapping(3)}
        {mapping(4)}
      </>
    );
  } else if (count == 6) {
    return (
      <>
        {mapping(0)}
        {mapping(1)}
        {mapping(2)}
        {mapping(3)}
        {mapping(4)}
        {mapping(5)}
      </>
    );
  } else if (count == 7) {
    return (
      <>
        {mapping(0)}
        {mapping(1)}
        {mapping(2)}
        {mapping(3)}
        {mapping(4)}
        {mapping(5)}
        {mapping(6)}
      </>
    );
  } else if (count == 8) {
    return (
      <>
        {mapping(0)}
        {mapping(1)}
        {mapping(2)}
        {mapping(3)}
        {mapping(4)}
        {mapping(5)}
        {mapping(6)}
        {mapping(7)}
      </>
    );
  } else if (count == 9) {
    return (
      <>
        {mapping(0)}
        {mapping(1)}
        {mapping(2)}
        {mapping(3)}
        {mapping(4)}
        {mapping(5)}
        {mapping(6)}
        {mapping(7)}
        {mapping(8)}
      </>
    );
  } else if (count == 10) {
    return (
      <>
        {mapping(0)}
        {mapping(1)}
        {mapping(2)}
        {mapping(3)}
        {mapping(4)}
        {mapping(5)}
        {mapping(6)}
        {mapping(7)}
        {mapping(8)}
        {mapping(9)}
      </>
    );
  }
}

export default TextBlock;
