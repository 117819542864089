import * as React from "react";

function IconArrow(props) {
  return (
    <svg
      viewBox="0 0 31 31"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      width="100%"
      height="100%"
      {...props}
    >
      <path
        d="M13.657 23.045a.999.999 0 01-.707-1.707l6.087-6.087-6.057-6.058a.999.999 0 111.414-1.414l6.764 6.765a1 1 0 010 1.414l-6.794 6.794a.999.999 0 01-.707.293z"
        fillRule="nonzero"
        fill="white"
      />
      <path
        d="M15.25 0C6.841 0 0 6.842 0 15.251c0 8.409 6.841 15.25 15.25 15.25 8.41 0 15.25-6.841 15.25-15.25C30.5 6.842 23.66 0 15.25 0zm0 2C22.569 2 28.5 7.933 28.5 15.251c0 7.317-5.931 13.25-13.25 13.25C7.932 28.501 2 22.568 2 15.251 2 7.933 7.932 2 15.25 2z"
        fillRule="nonzero"
        fill="white"
      />
    </svg>
  );
}

const MemoIconArrow = React.memo(IconArrow);
export default MemoIconArrow;
