import { React, useState, useRef, useEffect, useLayoutEffect } from "react";
import { addScaleCorrection, motion } from "framer-motion/dist/framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

import Pobj from "../../images/p2_obj.jpg";
import Prm from "../../images/p3_rm.jpg";

import { RiMapPin2Fill } from "react-icons/ri";
import MemoLogoCon from "../LogoCon";

import styled from "styled-components";

/* basic -------------------------------------------------------------------------------------- */
export const Artboard = (props) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  /* Need this reference to make this element bigger on user click to show elements */
  const divRef = useRef();

  useEffect(() => {}, [inView]);

  useEffect(() => {
    console.log("questo è un test");
  }, []);

  const [screen, setScreen] = useState(true);

  /* this trigger the new position for clickable element */
  function onTap(event, info) {
    if (screen) {
      setScreen(false);
    } else {
      setScreen(true);
    }
  }

  /* function for make the clicked element more bigger */

  return (
    <motion.div
      className={props.model}
      animate={inView ? "anim" : "null"}
      initial="init"
      variants={parentVariants}
      ref={ref}
    >
      <motion.div className={"borderInfo"} variants={childVariants}>
        <h2>
          <RiMapPin2Fill style={{ marginRight: "5px", fontSize: "0.8em" }} />
          {props.where}
        </h2>
        <h3>{props.number}</h3>
      </motion.div>

      <Link to={props.destination}>
        <motion.div
          className={"image"}
          ref={divRef}
          variants={childVariants}
          onTap={onTap}
        >
          <motion.img
            src={props.image}
            whileHover={{ scale: 1.2 }}
          ></motion.img>
        </motion.div>
      </Link>

      <motion.h1 variants={childVariants}>{props.title}</motion.h1>
      <motion.h3 variants={childVariants}>{props.info}</motion.h3>
    </motion.div>
  );
};

/* 1 -------------------------------------------------------------------------------------- */

const LogoContainer = styled.div`
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;

  display: flex;
  justify-content: center;

  width: 13vw;

  @media (max-width: 767px) {
    width: 33vw;
  }
`;

export const Artboard_1 = (props) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  /* Need this reference to make this element bigger on user click to show elements */
  const divRef = useRef();

  useEffect(() => {}, [inView]);

  useEffect(() => {
    console.log("questo è un test");
  }, []);

  const [screen, setScreen] = useState(true);

  /* this trigger the new position for clickable element */
  function onTap(event, info) {
    if (screen) {
      setScreen(false);
    } else {
      setScreen(true);
    }
  }

  /* function for make the clicked element more bigger */

  return (
    <motion.div
      className={props.model}
      animate={inView ? "anim" : "null"}
      initial="init"
      variants={parentVariants}
      ref={ref}
    >
      <motion.div className={"borderInfo"} variants={childVariants}>
        <h2>
          <RiMapPin2Fill style={{ marginRight: "5px", fontSize: "0.8em" }} />
          {props.where}
        </h2>
        <h3>{props.number}</h3>
      </motion.div>

      <Link to={props.destination}>
        <motion.div
          className={"image"}
          ref={divRef}
          variants={childVariants}
          onTap={onTap}
          style={{
            backgroundColor: "rgb(1, 3, 90)",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <motion.img
            src={props.image}
            whileHover={{ scale: 1.2 }}
          ></motion.img>

          <LogoContainer>
            <MemoLogoCon></MemoLogoCon>
          </LogoContainer>
        </motion.div>
      </Link>

      <motion.h1 variants={childVariants}>{props.title}</motion.h1>
      <motion.h3 variants={childVariants}>{props.info}</motion.h3>
    </motion.div>
  );
};

// animations

const parentVariants = {
  init: {},

  anim: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: 1,
    },
  },
};

const childVariants = {
  init: {
    y: 150,

    opacity: "0%",
  },

  anim: {
    y: 0,
    opacity: "100%",

    transition: {
      type: "spring",
      duration: 2,
      ease: "easeInOut",
      /*  damping: 20, */
    },
  },
};
