import { React, useState, useRef, useEffect, useLayoutEffect } from "react";

import {
  motion,
  useDragControls,
  useTransform,
  useViewportScroll,
  useMotionValue,
  useElementScroll,
} from "framer-motion/dist/es/index";

import images from "../Images";

import { useInViewScroll } from "framer-motion-hooks";
import { useInView } from "react-intersection-observer";

import { isMobile, isMobileOnly } from "react-device-detect";

/* Smooth Image */
import RenderSmoothImage from "render-smooth-image-react";
import "render-smooth-image-react/build/style.css";

import ReactPlayer from "react-player";

export default function PaperCase(props) {
  /* IntersectionObserver */
  const [videoContainer, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  /* Bigger image on scroll */
  let iconContainer = useRef();
  const progress = useInViewScroll(iconContainer);
  const special = useTransform(progress, [0, 0.1], [70 + "%", 100 + "%"]);

  const opacityAnimation = useTransform(progress, [0.7, 1], [1, 0.1]);

  return (
    <motion.div
      className="paperCase"
      style={{
        opacity: opacityAnimation,
      }}
      ref={iconContainer}
    >
      {props.src.includes("mp4") ? (
        <div
          className="videoContainer"
          style={{ backgroundColor: props.videoColor }}
          ref={videoContainer}
        >
          <ReactPlayer
            playsinline
            autoPlay
            muted={true}
            playing={inView ? true : false}
            url={props.src}
            loop={true}
            controls={false}
            width={isMobile ? "100%" : "70%"}
            height={isMobile ? "100%" : "70%"}
            style={{ margin: "auto" }}
            /* type="video/mp4" */
          />
        </div>
      ) : (
        <RenderSmoothImage
          src={props.src}
          alt="Something went wrong, please restart the page."
        />
      )}
    </motion.div>
  );
}
