import { React, useState, useRef, useEffect, useLayoutEffect } from "react";

import MediaQuery from "react-responsive";
import UseAnimations from "react-useanimations";

import { Sling as Hamburger } from "hamburger-react";
import { HiHeart } from "react-icons/hi";

import { SiMaildotru } from "react-icons/si";

import {
  motion,
  useDragControls,
  useTransform,
  useViewportScroll,
  useMotionValue,
  useElementScroll,
} from "framer-motion/dist/es/index";

import { BsArrowRightShort, BsChevronCompactLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import TextBlock from "../TextBlock/TextBlock";

function Header() {
  /* Mi serve per far scomparire l'header sullo scrolling verso l'alto */
  const { scrollYProgress } = useViewportScroll();
  const scrolling = useTransform(scrollYProgress, [0, 1], [0, 10]);

  const headerPosition = useMotionValue("-100%");

  useEffect(() => {
    document.addEventListener("scroll", () => {
      /* ------------------------------------------ scroll up  */
      if (scrolling.current < scrolling.prev) {
        headerPosition.set("0%");
      } else if (scrolling.current > scrolling.prev) {
        /* ------------------------------------------  scroll down  */
        headerPosition.set("-100%");
      }
    });
  });

  /* Mi serve per far scomparire l'header sullo scrolling verso l'alto */

  /* need this for making visible the mobile menu on click */
  const [burgerState, setBurgerState] = useState(false);

  /* need this for making visible the mobile menu on click */

  return (
    <>
      <MediaQuery maxWidth={"800px"}>
        <motion.div
          className="mobileMenu"
          initial={{ y: "-100%" }}
          animate={burgerState ? { y: "0%" } : { y: "-100%" }}
          transition={
            burgerState
              ? { duration: 0.5, delay: 0.2 }
              : { duration: 0.5, delay: 0 }
          }
        >
          <div>
            <Link to={"/"}>
              <motion.div
                className={"containerLink"}
                onTap={() => setBurgerState(!burgerState)}
              >
                <TextBlock
                  centerAligned={true}
                  miniTitle={""}
                  firstTitle={"+Home"}
                  secondTitle={""}
                  miniColor={"red"}
                  firstColor={"white"}
                  secondColor={"red"}
                />
              </motion.div>
            </Link>

            <Link to={"/gallery"}>
              <motion.div
                className={"containerLink"}
                onTap={() => setBurgerState(!burgerState)}
              >
                <TextBlock
                  centerAligned={true}
                  miniTitle={""}
                  firstTitle={"+Work"}
                  secondTitle={""}
                  miniColor={"red"}
                  firstColor={"white"}
                  secondColor={"red"}
                />
              </motion.div>
            </Link>
          </div>
          <motion.h4
            animate={burgerState ? { y: 0 } : { y: "100%" }}
            transition={
              burgerState
                ? { delay: 1.3, duration: 0.2 }
                : { delay: 0, duration: 0.2 }
            }
          >
            Designed and developed by <strong>Hugo™</strong> with{" "}
            <HiHeart style={{ color: "white" }} /> | 2022 |
          </motion.h4>
        </motion.div>
      </MediaQuery>

      <motion.div
        className="headerWrapper"
        style={burgerState ? { y: 0 } : { y: headerPosition }}
        animate={
          burgerState
            ? { backgroundColor: "rgb(224, 38, 59)" }
            : { backgroundColor: "#0f0f0f" }
        }
        transition={
          burgerState ? { duration: 0.2 } : { duration: 0.1, delay: 0.5 }
        }
      >
        <div className="wrapperInside">
          <div className={"logoContainer"}>
            <div className={"logoSquare"}></div>
          </div>

          <MediaQuery minWidth={"800px"}>
            <nav className={"back"}>
              <Link to="/">
                <motion.li whileHover={{ color: "rgb(224, 38, 59)" }}>
                  Home
                </motion.li>
              </Link>

              <Link to="/gallery">
                <motion.li whileHover={{ color: "rgb(224, 38, 59)" }}>
                  Work
                </motion.li>
              </Link>

              <a href="mailto: 01motes-tiered@icloud.com" target={"_blank"}>
                <motion.h1 whileHover={{ rotate: 360 }}>
                  <SiMaildotru
                    className={"left"}
                    style={{ fontSize: "1.5em", margin: 0 }}
                  />
                </motion.h1>
              </a>
            </nav>
          </MediaQuery>

          <MediaQuery maxWidth={"800px"}>
            <motion.div onTap={() => setBurgerState(!burgerState)}>
              <motion.div
                className={"hamburgerContainer"}
                onTap={() => setBurgerState(!burgerState)}
              >
                <Hamburger
                  color={"rgb(255, 255, 255)"}
                  size={24}
                  rounded
                  /* duration={0.4} */
                  toggled={burgerState}
                />
              </motion.div>
            </motion.div>
          </MediaQuery>
        </div>
      </motion.div>
    </>
  );
}

export default Header;
