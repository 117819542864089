import { React, useState, useRef, useEffect, useLayoutEffect } from "react";
import { motion } from "framer-motion/dist/es/index";
import MemoLogo from "../Icons/Logo";

import MediaQuery from "react-responsive";

import { Icon } from "../Icon/Icon.js";

import { StickyContainer, Sticky } from "react-sticky";

import TextBlock from "../TextBlock/TextBlock";

const Intro = () => {
  return (
    <div className={"logoTest"}>
      <MemoLogo />

      <div className={"spacerLogo"}></div>

      {/*   <MediaQuery maxWidth={"800px"}>
        <motion.div className="handIcon">
          <Icon anima={true} model={0} big={true} />
        </motion.div>
      </MediaQuery> */}

      <div className={"container"}>
        <div className={"intro"}>
          <div className={"text"}>
            <TextBlock
              centerAligned={false}
              miniTitle={"+intro"}
              firstTitle={"+Hi+there!"}
              secondTitle={"+Hugo+Here."}
              miniColor={"red"}
              firstColor={"white"}
              secondColor={"#ED0515"}
              bigger={true}
            />

            <motion.div className="handIcon">
              <Icon anima={true} model={0} big={true} parallax={0} />
            </motion.div>
          </div>

          <div className={"description"}>
            <p>
              I'm a designer and developer working with company and individuals
              all over the world. This is a non-exhaustive selection of my
              professional work.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
