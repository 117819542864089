import { React, useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  addScaleCorrection,
  AnimatePresence,
  motion,
  useTransform,
} from "framer-motion/dist/es/index";

import { useInViewScroll } from "framer-motion-hooks";
import { useInView } from "react-intersection-observer";
import Work from "../Work/Work";

import Icon from "../Icon/Icon.js";
import { Artboard } from "../Artboard/Artboard";
import { BrowserRouter, Switch, Route, Routes, Link } from "react-router-dom";
import Dragger from "../Dragger/Dragger";

/* previews */
import P_nhb from "../../images/p1_nhb.jpg";
import P_Obj from "../../images/p2_obj.jpg";
import P_rm from "../../images/p3_rm.jpg";
import P_lp from "../../images/p1_lp.jpg";
import P_dm from "../../images/p9.jpg";
import P_9 from "../../images/p9.jpg";

import { BsArrowRightShort } from "react-icons/bs";
import TextBlock from "../TextBlock/TextBlock";

const MiniGallery = () => {
  /* line longer on scroll */
  let iconContainer = useRef();
  const progress = useInViewScroll(iconContainer);
  const special = useTransform(progress, [0, 1], [10 + "px", 150 + "px"]);
  const specialOpacity = useTransform(progress, [0, 1], [0 + "%", 150 + "%"]);

  return (
    <motion.div
      className={"gallery"}
      key="modal"
      variants={variants}
      animate="anim"
      initial="init"
      exit="exit"
    >
      <TextBlock
        centerAligned={false}
        miniTitle={"+small+selection"}
        firstTitle={"+Latest+works+"}
        secondTitle={"+a+sneak+peek."}
        miniColor={"#ED0515"}
        firstColor={"white"}
        secondColor={"#ED0515"}
      />

      <div className={"workContainer"}>
        <Artboard
          model={"artboard_1"}
          title={"Brand identity for a python hobbyists club"}
          info={"#LOGODESIGN"}
          number={"01"}
          where={"Niagara Falls"}
          image={P_nhb}
          destination={"/case1"}
        />

        <Artboard
          model={"artboard_1"}
          title={"A new website for an OBGYN practice in Florida"}
          info={"#webdesign #sitebuilding #stationery"}
          number={"02"}
          where={"Jacksonville"}
          image={P_Obj}
          destination={"/case2"}
        />
        <Artboard
          model={"artboard_1"}
          title={"Logo and Web-Brandguide for a child psychiatrist"}
          info={"#logodesign #webdesign #Coding #Stationery"}
          number={"03"}
          where={"Maryland"}
          destination={"/case3"}
          image={P_9}
        />
      </div>

      <motion.div
        className={"callToGallery"}
        ref={iconContainer}
        style={{ opacity: specialOpacity }}
      >
        <motion.span className="line" style={{ height: special }}></motion.span>
        <Link to="/gallery">
          <h1>
            more work
            <BsArrowRightShort
              className={"left"}
              style={{ fontSize: "1.4em", margin: 0 }}
            />
          </h1>
        </Link>
      </motion.div>
    </motion.div>
  );
};

// animations

const variants = {
  init: {
    x: 0,
    opacity: 1,
  },

  anim: {
    x: 0,
    opacity: 1,
  },

  exit: {
    x: "-100vw",
    opacity: 0,
    transition: {
      type: "spring",
      duration: 3000,
      ease: "easeInOut",
      damping: 20,
    },
  },
};

export default MiniGallery;
