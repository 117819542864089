import * as React from "react";

function Star(props) {
  return (
    <div>
      <svg
        id="prefix__Livello_1"
        data-name="Livello 1"
        viewBox="0 0 311.16 52.46"
        width="80%"
        height="80%"
        {...props}
      >
        <defs>
          <style>{".prefix__2{fill:#d6d900}"}</style>
        </defs>
        <path
          id="prefix___s5_"
          data-name="&lt;s5&gt;"
          className="prefix__2"
          d="M27.58 0l9.55 15.86 18 4.18L43 34l1.6 18.44-17.02-7.2-17 7.22L12.14 34 0 20l18-4.18z"
        />
        <path
          id="prefix___s4_"
          data-name="&lt;s4&gt;"
          className="prefix__2"
          d="M91.58 0l9.55 15.86 18 4.18L107 34l1.6 18.44-17-7.22-17 7.22L76.14 34 64 20l18-4.18z"
        />
        <path
          id="prefix___s3_"
          data-name="&lt;s3&gt;"
          className="prefix__2"
          d="M155.58 0l9.55 15.86 18 4.18L171 34l1.6 18.44-17-7.22-17.05 7.22L140.14 34 128 20l18-4.18z"
        />
        <path
          id="prefix___s2_"
          data-name="&lt;s2&gt;"
          className="prefix__2"
          d="M219.58 0l9.55 15.86 18 4.18L235 34l1.6 18.44-17-7.22-17.05 7.22L204.14 34 192 20l18-4.18z"
        />
        <path
          id="prefix___s1_"
          data-name="&lt;s1&gt;"
          className="prefix__2"
          d="M283.58 0l9.55 15.86 18 4.18L299 34l1.6 18.44-17.05-7.22-17.05 7.22L268.14 34 256 20l18-4.18z"
        />
      </svg>
    </div>
  );
}

const MemoStar = React.memo(Star);
export default MemoStar;
