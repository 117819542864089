import { react, useRef, useState, useEffect } from "react";
import Skills from "../Skills/Skills";
import {
  motion,
  useDragControls,
  useTransform,
  useViewportScroll,
  useMotionValue,
  useElementScroll,
  animate,
  whileInView,
} from "framer-motion/dist/es/index";

import { useMotionAsState, useInViewScroll } from "framer-motion-hooks";

import { useInView } from "react-intersection-observer";

/* Autocounter */
import { useCountUp } from "react-countup";
import CountUp from "react-countup";

/* custom hooks */
import useRefScrollProgress from "../HOOKS/customHooks";

/* icons component from react-icons */
import { DiCss3Full } from "react-icons/di";
import { ImHtmlFive2 } from "react-icons/im";
import { SiJavascript } from "react-icons/si";
import { FaSass, FaReact, FaElementor } from "react-icons/fa";
import { FiFramer } from "react-icons/fi";

import { DiIllustrator, DiPhotoshop } from "react-icons/di";
import { SiAffinitydesigner } from "react-icons/si";
import { FiFigma } from "react-icons/fi";
import { BsVectorPen } from "react-icons/bs";
import { BiGitCompare } from "react-icons/bi";
import { SiVisualstudiocode } from "react-icons/si";

/* style for icons */
let iconSize = "2.5em";
let iconColor = "white";

const designSkills = [
  [
    "Illustrator",
    "the vector king",
    <DiIllustrator
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,
    "80%",
    80,
  ],
  [
    "Photoshop",
    "for realistic mockups",
    <DiPhotoshop
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,
    "40%",
    40,
  ],
  [
    "Affinity",
    "fast and furios",
    <SiAffinitydesigner
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,

    "70%",
    70,
  ],
  [
    "Figma",
    "for webdesign",
    <FiFigma
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,

    "40%",
    40,
  ],
  [
    "Procreate",
    "for sketch",
    <BsVectorPen
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,

    "40%",
    40,
  ],
  [
    "Github",
    "to handle everything",
    <BiGitCompare
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,

    "30%",
    30,
  ],
  [
    "VScode",
    " A great plugin eco-system",
    <SiVisualstudiocode
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,

    "30%",
    50,
  ],
];

const codingSkills = [
  [
    "HTML",
    "the bones of the web",
    <DiCss3Full
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,
    "60%",
    60,
  ],
  [
    "CSS",
    "I just Love it",
    <ImHtmlFive2
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,
    "80%",
    80,
  ],
  [
    "Javascript",
    "Making Website Magic",
    <SiJavascript
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,
    "60%",
    60,
  ],
  [
    "Sass",
    "Maintainable CSS",
    <FaSass
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,
    "40%",
    40,
  ],
  [
    "React",
    " A LOT OF REACTIVITY",
    <FaReact
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,
    "40%",
    40,
  ],
  [
    "Framer-motion",
    " for VISUAL EFFECTS",
    <FiFramer
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,
    "60%",
    60,
  ],
  [
    "Elementor",
    "Great sitebuilder on WP",
    <FaElementor
      style={{
        color: iconColor,
        fontSize: iconSize,
        marginRight: "0.4em",
      }}
    />,
    "70%",
    70,
  ],
];

export default function TableSkills(props) {
  let iconContainer = useRef();

  const progress = useInViewScroll(iconContainer);

  let specialAnimation = 20;

  /* IntersectionObserver */
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: false,
  });

  useEffect(() => {}, [inView]);

  const counter = useTransform(progress, [0, 0.4], [0, 100]);

  /* code for counter */

  const reactState = useMotionAsState(counter);

  if (props.skills == "designSkills") {
    return (
      <div className={"tableSkills"} ref={iconContainer}>
        {designSkills.map((name, index) => {
          return (
            <div className={"single"} ref={ref}>
              <motion.div
                className={"percentageBar"}
                initial={{ x: "0%", skew: 0 }}
                animate={inView ? { x: name[3], skew: -10 } : "null"}
                transition={{ duration: 1 }}
              ></motion.div>

              <div className={"iconAndText"}>
                <div className={"icon"}>{name[2]}</div>

                <div>
                  <h1>{name[0]}</h1>
                  <h2>{name[1]}</h2>
                </div>
              </div>

              <motion.div
                className={"percentageNumber"}
                initial={{ opacity: 0.2 }}
                animate={inView ? { opacity: 1 } : "null"}
                transition={{ duration: 0.5 }}
              >
                <motion.h4>
                  <CountUp
                    end={name[4]}
                    delay={0}
                    duration={1}
                    start={inView}
                  />{" "}
                  %
                </motion.h4>
              </motion.div>
            </div>
          );
        })}
      </div>
    );
  } else if (props.skills == "codingSkills") {
    return (
      <div className={"tableSkills"} ref={iconContainer}>
        {codingSkills.map((name, index) => {
          return (
            <div className={"single"} ref={ref}>
              <motion.div
                className={"percentageBar"}
                initial={{ x: "0%", skew: 0 }}
                animate={inView ? { x: name[3], skew: -10 } : "null"}
                transition={{ duration: 1 }}
              ></motion.div>

              <div className={"iconAndText"}>
                <div className={"icon"}>{name[2]}</div>

                <div>
                  <h1>{name[0]}</h1>
                  <h2>{name[1]}</h2>
                </div>
              </div>

              <div className={"percentageNumber"}>
                <motion.h4
                  initial={{ opacity: 0.2 }}
                  animate={inView ? { opacity: 1 } : "null"}
                  transition={{ duration: 0.5 }}
                >
                  <CountUp
                    end={name[4]}
                    delay={0}
                    duration={1}
                    start={inView}
                  />{" "}
                  %
                </motion.h4>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
