import { React, useState, useRef, useEffect, useLayoutEffect } from "react";
import { addScaleCorrection, AnimatePresence, motion, useTransform } from "framer-motion/dist/es/index";

import { useInViewScroll } from "framer-motion-hooks";
import { useInView } from "react-intersection-observer";
import Work from "../Work/Work";

import Icon from "../Icon/Icon.js";
import { Artboard } from "../Artboard/Artboard";
import { BrowserRouter, Switch, Route, Routes, Link } from "react-router-dom";
import Dragger from "../Dragger/Dragger";

/* previews */
import P_nhb from "../../images/p1_nhb.jpg";
import P_Obj from "../../images/p2_obj.jpg";
import P_rm from "../../images/p3_rm.jpg";
import P_lp from "../../images/p1_lp.jpg";
import P_dm from "../../images/p9.jpg";
import P_9 from "../../images/p9.jpg";

import { BsArrowRightShort } from "react-icons/bs";
import TextBlock from "../TextBlock/TextBlock";

const BooleanGallery = () => {
    /* line longer on scroll */
    let iconContainer = useRef();
    const progress = useInViewScroll(iconContainer);
    const special = useTransform(progress, [0, 1], [10 + "px", 150 + "px"]);
    const specialOpacity = useTransform(progress, [0, 1], [0 + "%", 150 + "%"]);

    return (
        <motion.div className={"galleryBoolean"} key="modal" variants={variants} animate="anim" initial="init" exit="exit">
            <TextBlock centerAligned={false} miniTitle={"+bootcamp+projects"} firstTitle={"+My+Bootcamp"} secondTitle={"+projects."} miniColor={"#ED0515"} firstColor={"white"} secondColor={"#ED0515"} />

            {/*  <h1 className="b-title">Bootcamp experience</h1> */}
            <p>I just completed a web development bootcamp, where I focused on clone projects replicating popular websites and apps. I had a lot of fun working on them, and it helped me to understand how these sites are built. Overall, it was a great experience, and I feel more confident in my abilities as a developer.</p>

            <div className="line">
                <a className="project-container" href="https://avada-movers-clone.netlify.app" target="_blank">
                    <div>
                        <h1>Avada theme wordpress clone</h1>
                        <h2>#html #css #vue</h2>
                    </div>

                    <div className="button">
                        <h3>external link</h3>

                        <div className="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                            </svg>
                        </div>
                    </div>
                </a>
            </div>

            <div className="line">
                <a className="project-container" href="https://spotify-clone-boolean.netlify.app" target="_blank">
                    <div>
                        <h1>Spotify web clone</h1>
                        <h2>#html #css</h2>
                    </div>

                    <div className="button">
                        <h3>external link</h3>

                        <div className="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                            </svg>
                        </div>
                    </div>
                </a>
            </div>

            <div className="line">
                <a className="project-container" href="https://glittery-cascaron-1c6ac3.netlify.app" target="_blank">
                    <div>
                        <h1>Playstation website clone</h1>
                        <h2>#html #css #bootstrap</h2>
                    </div>

                    <div className="button">
                        <h3>external link</h3>

                        <div className="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                            </svg>
                        </div>
                    </div>
                </a>
            </div>

            <div className="line">
                <a className="project-container" href="https://movie-finder-boolean.netlify.app" target="_blank">
                    <div>
                        <h1>Movie Finder webapp</h1>
                        <h2>#vue #axios</h2>
                    </div>

                    <div className="button">
                        <h3>external link</h3>

                        <div className="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                            </svg>
                        </div>
                    </div>
                </a>
            </div>

            <div className="line">
                <a className="project-container" href="https://whatsapp-boolean.netlify.app" target="_blank">
                    <div>
                        <h1>Whatsapp web clone</h1>
                        <h2>#vue</h2>
                    </div>

                    <div className="button">
                        <h3>external link</h3>

                        <div className="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                            </svg>
                        </div>
                    </div>
                </a>
            </div>
        </motion.div>
    );
};

// animations

const variants = {
    init: {
        x: 0,
        opacity: 1,
    },

    anim: {
        x: 0,
        opacity: 1,
    },

    exit: {
        x: "-100vw",
        opacity: 0,
        transition: {
            type: "spring",
            duration: 3000,
            ease: "easeInOut",
            damping: 20,
        },
    },
};

export default BooleanGallery;
