import { React, useState, useRef, useEffect, useLayoutEffect } from "react";

import {
  motion,
  useDragControls,
  useTransform,
  useViewportScroll,
  useMotionValue,
  useElementScroll,
} from "framer-motion/dist/es/index";

import { useMotionAsState, useInViewScroll } from "framer-motion-hooks";

const variants = {
  init: {},
  anim: {
    transition: {
      staggerChildren: 0,
      staggerDirection: 0,
    },
  },
};

const variantsLeft = {
  init: { opacity: "0%", x: 1000, scale: 10 },
  anim: {
    opacity: "100%",
    x: 0,
    scale: 1,
    transition: {
      repeat: 0,
      type: "spring",
      stiffness: 80,
    },
  },
};

const variantsRight = {
  init: { opacity: "0%", x: -1000, scale: 10 },
  anim: {
    opacity: "100%",
    x: 0,
    scale: 1,
    transition: { repeat: 0, type: "spring", stiffness: 80 },
  },
};

const variantsLetter = {
  init: { opacity: "10%", rotate: 360, scale: 0 },
  anim: {
    opacity: "100%",
    scale: 1,
    rotate: 0,
    transition: {
      duration: 2,
      repeat: 0,
      delay: 0.7,
      type: "spring",
      stiffness: 80,
    },
  },
};

function Logo(props) {
  let iconContainer = useRef();
  const progress = useInViewScroll(iconContainer);

  const s1 = useTransform(progress, [0.1, 1], [0, 400]);
  const s2 = useTransform(progress, [0.1, 1], [0, -400]);

  const tranH = useTransform(progress, [0.1, 1], [0, -400]);
  const tranU = useTransform(progress, [0.1, 1], [0, -400]);

  const tranG = useTransform(progress, [0.1, 1], [0, 400]);
  const tranO = useTransform(progress, [0.1, 1], [0, 400]);

  const s3 = useTransform(progress, [0.1, 1], [1, 1]);
  const s4 = useTransform(progress, [0.1, 1], [0 + "deg", 20 + "deg"]);
  const s5 = useTransform(progress, [0.1, 1], [0 + "deg", 20 + "deg"]);
  const s6 = useTransform(progress, [0.1, 1], [1, 1]);

  const opacity = useTransform(progress, [0, 1], [1, 0]);

  const fixedState = useTransform(progress, [0, 0.5, 1], [1, 1, 2]);

  const reactState = useMotionAsState(fixedState);

  const opacityTranslation = useTransform(
    progress,
    [0.9, 1],
    [100 + "%", 0 + "%"]
  );

  const goDown = useTransform(progress, [0.1, 0.5], [0 + "px", 1000 + "px"]);

  return (
    <motion.div
      style={{
        originX: "center",
      }}
      className="preLogoContainer"
      ref={iconContainer}
    >
      <motion.svg
        variants={variants}
        style={{
          originX: "center",
          skewY: s4,
          skewX: s5,
          scale: s6,

          fillOpacity: opacityTranslation,
        }}
        animate="anim"
        initial="init"
        viewBox="0 0 290.38 221.21"
        preserveAspectRatio="xMidYMid"
        width="100%"
        height="100%"
        {...props}
      >
        <defs>
          <linearGradient
            id="prefix__Sfumatura_senza_nome"
            x1={-599.42}
            y1={549.74}
            x2={-598.42}
            y2={549.74}
            gradientTransform="scale(-140.94 140.94) rotate(70.615 88.247 697.727)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="red" />
            <stop offset={0.26} stopColor="#fb0105" />
            <stop offset={0.5} stopColor="#ec0516" />
            <stop offset={0.77} stopColor="#7c3b9d" />
            <stop offset={1} stopColor="#4f48c5" />
          </linearGradient>
          <linearGradient
            id="prefix__Sfumatura_senza_nome_2"
            x1={-602.28}
            y1={549.97}
            x2={-601.28}
            y2={549.97}
            gradientTransform="rotate(-103.61 -58017.203 4286.624) scale(112.56)"
            xlinkHref="#prefix__Sfumatura_senza_nome"
          />
          <style>{".prefix__cls-1{fill: rgb(255,255,255)}"}</style>
        </defs>
        <g id="prefix___h_" data-name="&lt;h&gt;">
          <motion.path
            style={{ translateX: tranH }}
            variants={variantsLetter}
            id="prefix__h"
            className="prefix__cls-1"
            d="M112.38 27.82c-10.45 0-18.38 3.59-22.64 9.18V0H66v104.71h23.74V63.87c0-10.62 6-15.41 14.72-15.41 7.6 0 13.77 4.34 13.77 13.62v42.63H142V58.78c0-20.19-13.64-30.96-29.62-30.96z"
          />
        </g>
        <g id="prefix___u_" data-name="&lt;u&gt;">
          <motion.path
            style={{ translateX: tranU }}
            variants={variantsLetter}
            id="prefix__u"
            className="prefix__cls-1"
            d="M201.67 27.59v41c0 10.65-5.71 15.45-14 15.45-7.2 0-13.05-4.35-13.05-13.65v-42.8h-22.5v46.06c0 20.25 12.9 31.06 28.05 31.06 9.9 0 17.4-3.6 21.46-9.16v7.06h22.5v-75z"
          />
        </g>
        <g id="prefix___g_" data-name="&lt;g&gt;">
          <motion.path
            style={{ translateX: tranG }}
            variants={variantsLetter}
            id="prefix__g"
            className="prefix__cls-1"
            d="M118.5 115.14v7.58c-4.9-6.09-10.25-9.66-19.9-9.66-21.1 0-36.55 17.24-36.55 37.74s15.45 37.73 36.55 37.73c9.65 0 16.78-3.57 21.69-9.66v6.83c0 10.55-6.39 15.9-16.79 15.9-9.8 0-14.11-4.16-16.93-9.36l-19 11c6.83 12.19 19.76 18 35.21 18 19.75 0 39.21-10.55 39.21-35.51v-70.59zM102.31 168c-10.55 0-18-7-18-17.23s7.42-17.24 18-17.24 18 7 18 17.24-7.45 17.23-18 17.23z"
          />
        </g>
        <g id="prefix___o_" data-name="&lt;o&gt;">
          <motion.path
            style={{ translateX: tranO }}
            variants={variantsLetter}
            id="prefix__o"
            className="prefix__cls-1"
            d="M190 188.52a37.69 37.69 0 10-37.83-37.69A37.42 37.42 0 00190 188.52zm0-20.84c-9.28 0-16.42-6.71-16.42-16.85S180.71 134 190 134s16.41 6.71 16.41 16.84-7.14 16.84-16.41 16.84z"
          />
        </g>
        <g id="prefix___frecciaSinistra_" data-name="&lt;frecciaSinistra&gt;">
          <motion.path
            style={{ translateX: s2 }}
            id="prefix__left"
            variants={variantsLeft}
            d="M2.68 73.12a10.41 10.41 0 010-13.94L28.3 30.83a10.6 10.6 0 0115-.75 10.6 10.6 0 01.76 15L25 66.15l19 21.09a10.6 10.6 0 01-.76 15 10.61 10.61 0 01-15-.76z"
            fill="url(#prefix__Sfumatura_senza_nome)"
            fillRule="evenodd"
          />
        </g>
        <g id="prefix___frecciaDestra_" data-name="&lt;frecciaDestra&gt;">
          <motion.path
            style={{ translateX: s1 }}
            variants={variantsRight}
            id="prefix__right"
            d="M287.7 156.38a10.38 10.38 0 000-13.93c-7-7.76-18.3-20.25-25.62-28.35a10.61 10.61 0 00-15-.76 10.62 10.62 0 00-.75 15l19.06 21.08-19.04 21.08a10.61 10.61 0 00.75 15 10.6 10.6 0 0015-.75z"
            fill="url(#prefix__Sfumatura_senza_nome_2)"
            fillRule="evenodd"
          />
        </g>
      </motion.svg>
    </motion.div>
  );
}

/* const MemoLogo = React.memo(Logo); */
export default Logo;
