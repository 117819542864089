import * as React from "react";

function Logo99(props) {
  return (
    <svg viewBox="0 0 165.73 86.42" {...props} height="75%" width="75%">
      <path
        d="M149.25 2.21v29.38c-11.97-9.55-23.43-7.93-35.29-.51-1.41-15.13-8.29-25.83-22.82-29.85-14.7-4.06-25.79 2.22-34.42 14.18C47.71 3.21 36.56-2.97 21.56 1.48 8.7 5.29 1.08 14.48.12 27.5c-1.41 19.25 9.36 30.08 27.92 34.13-3.91 6.94-9.57 17.94-13.42 24.79h18.16c2.54-3.99 5.62-8.85 7.64-12.47 5.2-9.36 10.51-18.74 16.27-28.75 5.79 9.6 13.89 14.58 24.89 16.02-4.02 7.15-9.71 18.21-13.65 25.2h18.45c2.34-4.52 4.4-7.4 6.78-11.56 3.22-5.63 6.37-11.4 9.63-17.06 2.26 11.42 6.74 20.78 17.4 25.66 10.66 4.88 20.88 4.08 29.96-4.94.27 2.33.44 3.79.62 5.38h14.95V2.21h-16.47zM29.87 45.08C22.05 45 15.88 38.57 15.9 30.53c.02-8.08 6.1-14.22 14.12-14.24 7.86-.02 14.31 6.29 14.45 14.13.13 7.83-6.75 14.74-14.6 14.66zm53.29-.01c-7.79-.12-14.1-6.64-14.07-14.54.03-8.19 6.45-14.38 14.74-14.23 7.91.15 13.81 6.46 13.71 14.64-.1 7.95-6.51 14.25-14.38 14.13zm52.11 25.62c-8.48-.07-15.12-6.9-14.96-15.42.15-8.47 7.01-15.02 15.54-14.86 8.24.16 14.69 6.86 14.62 15.19-.08 8.71-6.58 15.16-15.2 15.09z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoLogo99 = React.memo(Logo99);
export default MemoLogo99;
