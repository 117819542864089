import React from "react";
import { motion, useAnimation } from "framer-motion/dist/es/index";

// this import a component for trunking texts

import { useInView } from "react-intersection-observer";
import { useState, useEffect } from "react";

// svg star
import MemoStar from "../Icons/star.js";
import { FaRegSmile } from "react-icons/fa";

const ReviewSlide = (props) => {
  const [expandReview, setExpand] = useState(40);

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
      });
    } else if (!inView) {
      animation.start({
        opacity: 0,
      });
    }
  }, [inView]);

  return (
    <motion.div className={"external_1"} animate={animation} ref={ref}>
      <MemoStar />

      <div className={"textConstraints"}>
        <p className={"textReview"}>{props.text}</p>
      </div>
      <div className={"bottomShadow"}></div>

      <h4>
        {" "}
        <FaRegSmile style={{ margin: 0, paddingRight: "5px" }} />
        Happy customer
      </h4>
    </motion.div>
  );
};

export default ReviewSlide;
