import React from "react";

/* import Icon from "../Icon/Icon"; */
import Skills from "../Skills/Skills";
import { motion } from "framer-motion/dist/es/index";

/* icons component from react-icons */
import { DiCss3Full } from "react-icons/di";
import { ImHtmlFive2 } from "react-icons/im";
import { SiJavascript } from "react-icons/si";
import { FaSass, FaReact, FaElementor } from "react-icons/fa";
import { FiFramer } from "react-icons/fi";

import { DiIllustrator, DiPhotoshop } from "react-icons/di";
import { SiAffinitydesigner } from "react-icons/si";
import { FiFigma } from "react-icons/fi";
import { BsVectorPen } from "react-icons/bs";
import { IoLogoDesignernews } from "react-icons/io";
import { CgIfDesign } from "react-icons/cg";
import TableSkills from "../TableSkills/TableSkills";
import TextBlock from "../TextBlock/TextBlock";

import { Icon } from "../Icon/Icon";

function SkillSection() {
  return (
    <div className={"skillSection"}>
      <div className={"skillSection__icon"}>
        <div>
          <motion.div
            animate={{ scale: [1, 1.1, 1, 1.2, 1] }}
            transition={{
              type: "spring",
              duration: 1,
              repeat: Infinity,
              /* repeatDelay: 1, */
            }}
          >
            <Icon model={1} medium={true} parallax={4} />
          </motion.div>

          <motion.div
            animate={{ scale: [1.1, 1, 1.1, 1, 1.1] }}
            transition={{
              type: "spring",
              duration: 1,
              repeat: Infinity,
              /* repeatDelay: 1, */
            }}
          >
            {/* <Icon model={1} small={true} /> */}
          </motion.div>
        </div>
      </div>

      <div className={"skillSection__text"}>
        <TextBlock
          centerAligned={true}
          miniTitle={"+My+Skills"}
          firstTitle={"+My+passion."}
          secondTitle={"+My+love."}
          miniColor={"#ED0515"}
          firstColor={"white"}
          secondColor={"#ED0515"}
        />
      </div>

      <div className={"tables"}>
        <TableSkills skills="designSkills" />
        <TableSkills skills="codingSkills" />
      </div>
    </div>
  );
}

const codingSkills = [
  [
    "HTML",
    "also semantic",
    <DiCss3Full
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
      }}
    />,
  ],
  [
    "CSS",
    "so cool",
    <ImHtmlFive2
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
      }}
    />,
  ],
  [
    "Javascript",
    "es6, yeahh",
    <SiJavascript
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
      }}
    />,
  ],
  [
    "Sass",
    "Maintainable CSS",
    <FaSass
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
      }}
    />,
  ],
  [
    "React",
    "Not a rockstar, just learning",
    <FaReact
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
      }}
    />,
  ],
  [
    "Framer-motion",
    "Cool motion library for react",
    <FiFramer
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
      }}
    />,
  ],
  [
    "Elementor",
    "Great sitebuilder, personal favorite",
    <FaElementor
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
      }}
    />,
  ],
];

const designSkills = [
  [
    "illustrator",
    "best for vector design",
    <DiIllustrator
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
        height: "100%",
      }}
    />,
  ],
  [
    "photoshop",
    "so cool",
    <DiPhotoshop
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
      }}
    />,
  ],
  [
    "affinity",
    "es6, yeahh",
    <SiAffinitydesigner
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
      }}
    />,
  ],
  [
    "Figma",
    "so manteinable",
    <FiFigma
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
      }}
    />,
  ],
  [
    "Vector Design",
    "Not a rockstar, just learning",
    <BsVectorPen
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
      }}
    />,
  ],
  [
    "Brand identity",
    "cool animation on react",
    <IoLogoDesignernews
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
      }}
    />,
  ],
  [
    "web design",
    "Great sitebuilder for wordpress",
    <CgIfDesign
      style={{
        color: "rgb(224, 38, 59)",
        fontSize: "4em",
        marginRight: "0.4em",
      }}
    />,
  ],
];

export default SkillSection;
