import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";

import { BrowserRouter } from "react-router-dom";

import "./style/globalStyle.css";
import reportWebVitals from "./reportWebVitals";

//Main Components

import App from "./App";
import { Router } from "react-router";
import Header from "./components/Header/Header";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
