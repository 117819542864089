import { React, useState, useRef, useEffect, useLayoutEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
  motion,
  useDragControls,
  useTransform,
  useViewportScroll,
  useMotionValue,
  useElementScroll,
} from "framer-motion/dist/es/index";
import Icon from "../Icon/Icon";

/* icons */
import { RiArrowRightUpLine } from "react-icons/ri";
import { FiInstagram } from "react-icons/fi";
import { RiGithubLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import MemoLogoFooter from "../Icons/LogoFooter";
import { HiHeart } from "react-icons/hi";
import MemoLogo99 from "./logo99";

const Footer = () => {
  return (
    <motion.div className={"footer"}>
      <div className={"contacts"}>
        <div className={"logo"}>
          <div className={"logoCopy"}>
            <div className={"logoContainer"}>
              <div className={"logoSquare"}></div>
            </div>

            {/*    <div className={"copy"}>
              <h4>
                Designed and developed by <strong>Hugo™</strong> with{" "}
                <HiHeart style={{ color: "red" }} /> | 2022 |
              </h4>
            </div> */}
          </div>

          <div className={"social"}>
            <a href="https://www.instagram.com/hugo_d_ev/" target={"_blank"}>
              <FiInstagram style={{ color: "white", fontSize: "2.5em" }} />
            </a>

            <a href="https://github.com/Hugocod" target={"_blank"}>
              <RiGithubLine style={{ color: "white", fontSize: "2.5em" }} />
            </a>

            <a
              className="logo99"
              href="https://99designs.it/profiles/2330568"
              target={"_blank"}
            >
              <MemoLogo99></MemoLogo99>
            </a>

            <a href="mailto: 01motes-tiered@icloud.com">
              <HiOutlineMail style={{ color: "white", fontSize: "2.5em" }} />
            </a>
          </div>
        </div>

        <div className={"copy"}>
          <h4>
            Designed and developed by <strong>Hugo™</strong> with{" "}
            <HiHeart style={{ color: "red" }} /> | 2022 |
          </h4>
        </div>
      </div>
    </motion.div>
  );
};

export default Footer;
