import { React, useState, useRef, useEffect, useLayoutEffect } from "react";

import {
  addScaleCorrection,
  AnimatePresence,
  motion,
  useTransform,
} from "framer-motion/dist/es/index";

import { useInViewScroll } from "framer-motion-hooks";

import { BsArrowRightShort } from "react-icons/bs";

import MemoLogoEnd from "../logoEnd";
import TextBlock from "../TextBlock/TextBlock";
import styled from "styled-components";

import { SiMaildotru } from "react-icons/si";
/* import Icon from "../Icon/Icon"; */

/* styled component */

const Button = styled.button`
  border: 2px solid white;
  border-radius: 1em;
  background: none;
  padding: 21px 30px;
  font-size: 16px;
  color: #fff;
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  transition: 0.25s ease-in-out;
`;

export default function End() {
  /* line longer on scroll */
  let iconContainer = useRef();
  const progress = useInViewScroll(iconContainer);
  const special = useTransform(progress, [0, 1], [10 + "px", 150 + "px"]);
  const specialOpacity = useTransform(progress, [0, 1], [0 + "%", 200 + "%"]);

  return (
    <div className="end">
      <div className="container">
        {/*  <Icon model={"iconContainer7"}></Icon> */}

        <TextBlock
          centerAligned={true}
          miniTitle={"+Hey,+stop+scrolling!"}
          firstTitle={"+Any+Ideas?"}
          secondTitle={"+let's+talk"}
          miniColor={"#ED0515"}
          firstColor={"white"}
          secondColor={"#ED0515"}
        />

        <a href="mailto: 01motes-tiered@icloud.com" target={"_blank"}>
          <motion.h1 whileHover={{ rotate: 360 }}>
            <SiMaildotru
              className={"left"}
              style={{ fontSize: "1.5em", margin: 0, color: "white" }}
            />
          </motion.h1>
        </a>

        {/* <MemoLogoEnd></MemoLogoEnd> */}
      </div>
    </div>
  );
}
